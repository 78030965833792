<template>
    <div class="activation-form">
        <div class="activation-form__content">
            <h1 class="activation-form__title">
                {{ $t('Two-Factor Authentication') }}
            </h1>
            <template v-if="secondsRemaining > 0">
                <p class="activation-form__text">
                    {{ $t('You can add an extra layer of security to your account by requesting access with your smartphone when you login, to do this you can follow the following steps.') }}
                </p>
                <div>
                    <ul class="activation-form__list">
                        <li>
                            {{ $t('Download and install Google Authenticator or any other authenticator app on your phone.') }}
                        </li>
                        <li>
                            {{ $t('Open the app, scan the QR code below, and enter the code it generates.') }}
                        </li>
                    </ul>
                    <img v-if="qrCode.url" :src="qrCode.url" alt="QR Code" class="activation-form__qr-code">
                </div>
                <button v-b-tooltip.hover class="application-form__copy-button link m-1" :title="qrCode.secret" @click="copy">
                    {{ qrCode.secret }}
                    <i class="far fa-copy"></i>
                </button>
                <form class="activation-form__form" @submit.prevent="handleSubmit()">
                    <div class="text-left">
                        <ui-input
                            id="verification_code"
                            v-model="formData.token"
                            name="verification_code"
                            type="number"
                            placeholder="000000"
                            :label="$t('Code')"
                            :field="v$.formData.token"
                        />
                    </div>
                    <ui-button
                        :loading="validatingCode"
                        class="w-100 mb-0"
                        type="submit"
                        variant="accent"
                    >
                        {{ $t('Validate code') }} {{ timeRemaining }}
                    </ui-button>
                </form>
                <div>
                    <p class="activation-form__support-message">
                        {{ $t('If you do not want to activate it at this time, you can') }}
                    </p>
                    <ui-button class="activation-form__support-button" variant="secondary" size="sm" @click="redirect">
                        {{ $t('Activate it later') }}
                    </ui-button>
                </div>
            </template>
            <div v-else class="activation-form__form mt-3">
                <empty-state
                    :text="$t('The time to enable two-factor authentication has expired, you need to log in again.')"
                    img="clock.svg"
                />
                <ui-button
                    :loading="validatingCode"
                    class="w-100 mb-0"
                    :to="{ path: '/login', query: getQueryParams() }"
                >
                    {{ $t('Login') }}
                </ui-button>
            </div>
        </div>
        <vue-recaptcha
            class="w-100"
            ref="recaptcha"
            size="invisible"
            @verify="validateCode"
            :sitekey="CONST.CAPTCHACODE"
            :loadRecaptchaScript="true"
        />
    </div>
</template>

<script>
import QR from 'qrcode';
import VueRecaptcha from 'vue-recaptcha';
import { required, helpers, minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core'

import EmptyState from '@/components/ui/EmptyState';
import UiButton from '@/components/ui/buttons/Button.vue';
import UiInput from '@/components/ui/inputs/Input.vue';

import recaptchaUtils from '@/utils/recaptcha.utils';

export default {
    name: 'ActivationForm',
    components: {
        EmptyState,
        UiButton,
        UiInput,
        VueRecaptcha,
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            formData: {
                otp_url: '',
                token: ''
            },
            loadingLogo: false,
            qrCode: {
                secret: '',
                url: ''
            },
            secondsRemaining: 0,
            timeRemaining: null,
            timeInterval: null,
            validatingCode: false,
            verificationCode: '',
        };
    },
    validations() {
        return {
            formData: {
                token: {
                    $autoDirty: true,
                    required: helpers.withMessage(this.$t('validations.required', { field: this.$t('code') }), required),
                    length: helpers.withMessage(({ $params }) => this.$t('This field must be a minimum of {min} characters', $params), minLength(6))
                },
            },
        };
    },
    mounted() {
        this.$nextTick(() => {
            const expiration_time = sessionStorage.getItem('tfa_expiration') || 0;
            const expiration_seconds = Math.floor(this.$moment.duration(this.$moment(expiration_time).diff(this.$moment())).asSeconds());

            this.setTimeRemaining(expiration_seconds);

            if (expiration_seconds > 0) {
                this.createQrCode();
            }
        })
    },
    methods: {
        getQueryParams() {
            const query = { ...this.$route.query };

            if (query.redirect_url) {
                const formatted_url = new URL(query.redirect_url);
                formatted_url.search = '';
                query.redirect_url = formatted_url.toString();
            }

            return query;
        },
        setTimeRemaining(expiration_seconds) {
            this.secondsRemaining = expiration_seconds;
            this.timeInterval = setInterval(() => {
                if (this.secondsRemaining > 0) {
                    this.secondsRemaining--;
                    this.timeRemaining = this.$moment().startOf('day').seconds(this.secondsRemaining).format('mm:ss')
                } else {
                    clearInterval(this.timeInterval);
                }
            }, 1000);
        },
        redirect() {
            const { redirect_url } = this.$route.query;
            window.location.replace(redirect_url)
        },
        async createQrCode() {
            try {
                const { data } = await this.axiosAccount.get('/api/two-factor/generate');
                const { otp_url } = data;
                this.qrCode.url = await QR.toDataURL(otp_url);
                this.qrCode.secret = new URL(data.otp_url).searchParams.get('secret');
                this.formData.otp_url = otp_url;
            } catch(error) {
                this.$toasted.global.errorMessage(error);
            }
        },
        async handleSubmit() {
            this.v$.$touch();

            if (this.v$.$invalid) {
                return;
            }

            if (!recaptchaUtils.isAvailable()) {
                this.validateCode();
                return;
            }

            this.$refs.recaptcha.reset();
            this.$refs.recaptcha.execute();
        },
        async validateCode() {
            try {
                this.validatingCode = true;
                
                const { redirect_url } = this.$route.query;
                await this.axiosAccount.post('/api/two-factor/verify', this.formData)

                window.location.replace(redirect_url)
            } catch (error) {
                this.showError(error);
            } finally {
                this.validatingCode = false;
            }
        },
        copy(value) {
            navigator.clipboard.writeText(value);
            this.$toasted.global.successMessage(this.$t('Copied to clipboard.'));
        },
    },
};
</script>

<style lang="scss">
.activation-form {
    text-align: center;
    position: relative;

    &__back {
        margin-right: 100%;

        @media(min-width: 992px) {
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &__content {
        max-width: 650px;
        margin-left: auto;
        margin-right: auto;
    }

    &__logo {
        margin-bottom: 35px;
    }

    &__title {
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 10px;
        
        @media(min-width: 768px) {
            font-size: 2.25rem;
        }
    }

    &__text {
        margin-bottom: 5px;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        width: 650px;
    }

    &__img {
        height: auto;
        margin-bottom: 30px;
        max-width: 118px;
        width: 100%;
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 24px;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        width: 450px;
    }

    &__support-message {
        font-size: 0.875rem;
        font-weight: 800;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        max-width: 450px;
    }

    &__list {
        max-width: 650px;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        text-align: center;
        width: 90%;
    }

    &__wait-message {
        background-color: rgba($general-info, 0.1);
        border-radius: 8px;
        color: $general-black;
        font-size: 0.9rem;
        font-weight: 400;
        margin-bottom: 0;
        padding: 8px;
    }

    &__qr-code {
        height: auto;
        max-width: 150px;
        width: 100%;
    }

    &__text-code {
        word-break: break-all;
    }

    &__copy-button {
        background-color: transparent;
        border: none;
        border-radius: 4px;
        color: #555555;
        padding: 1px 4px 1px 4px;

        &:hover {
            background-color: $accounts-secondary-100;
        }
    }
}
</style>
